<template>
  <Categoria
    titulo="Reportes de Inventario - Categoria"
    :servicioGetReporte="servicioGetReporte"
    :servicioGetReportePdf="servicioGetReportePdf"
    :componenteListado="componenteListado"
  />
</template>
  
<script>
import Categoria from "../components/Categoria.vue";
import ReporteInventarioService from "../services";

export default {
  name: "ReporteInventarioCategoriaLayout",
  components: {
    Categoria,
  },
  data() {
    return {
      componenteListado: "ListadoInventario",
    };
  },
  methods: {
    servicioGetReporte: ReporteInventarioService.getReporteInventarioCategoria,
    servicioGetReportePdf:
      ReporteInventarioService.getReporteInventarioCategoriaPdf,
  },
};
</script>
  